interface LocationData {
  country_code: string;
  country_name: string;
}

export const getUserLocation = async (): Promise<LocationData> => {
  try {
    let countryCode = localStorage.getItem('country_code');
    //const response = await fetch('https://ipapi.co/json/');
    //https://country.is/
    if (!countryCode) {
      const response = await fetch('https://api.country.is/');
      if (!response.ok) {
        throw new Error('Failed to fetch location data');
      }
      const data: { country: string } = await response.json();
      countryCode = data.country;
      localStorage.setItem('country_code', countryCode);
    }
    //console.log(data);
    return { country_code: countryCode, country_name: countryCode };
    //return await response.json();
  } catch (error) {
    console.error('Error getting user location:', error);
    return { country_code: 'US', country_name: 'United States' }; // default
  }
};

// export const getUserLocation = async (): Promise<LocationData> => {
//   try {
//     //const response = await fetch('https://ipapi.co/json/');
//     //https://country.is/
//     const response = await fetch('https://api.country.is/');
//     if (!response.ok) {
//       throw new Error('Failed to fetch location data');
//     }
//     const data = await response.json();
//     //console.log(data);
//     return { country_code: data.country, country_name: data.country };
//     //return await response.json();
//   } catch (error) {
//     console.error('Error getting user location:', error);
//     return { country_code: 'US', country_name: 'United States' }; // default
//   }
// };

export const getLanguageFromCountry = (countryCode: string): string => {
  // const countryLanguageMap = new Map<string, string>([
  //   ['US', 'en'], ['GB', 'en'], ['CA', 'en'],
  //   ['ES', 'es'], ['MX', 'es'], ['AR', 'es'],
  //   ['FR', 'fr'], ['DE', 'de'], ['IT', 'it'],
  //   ['PT', 'pt'], ['BR', 'pt'], ['RU', 'ru'],
  //   ['CN', 'zh'], ['TW', 'zh'], ['HK', 'zh'],
  //   ['JP', 'ja'], ['KR', 'ko']
  // ]);

  const countryLanguageMap = new Map<string, string>([
    ['US', 'en'], ['GB', 'en'], ['CA', 'en'],
    ['ES', 'es'], ['MX', 'es'], ['AR', 'es'],
    ['FR', 'fr'], ['DE', 'de'], ['IT', 'it'],
    ['PT', 'pt'], ['BR', 'pt'], ['RU', 'ru'],
    ['CN', 'zh'], ['TW', 'zh'], ['HK', 'zh'],
    ['JP', 'ja'], ['KR', 'ko'],
    ['AF', 'ps'], ['AL', 'sq'], ['DZ', 'ar'], ['AD', 'ca'], ['AO', 'pt'],
    ['AI', 'en'], ['AG', 'en'], ['AR', 'es'], ['AM', 'hy'], ['AW', 'pa'],
    ['AU', 'en'], ['AT', 'de'], ['AZ', 'az'], ['BS', 'en'], ['BH', 'ar'],
    ['BD', 'bn'], ['BB', 'en'], ['BY', 'be'], ['BE', 'nl'], ['BZ', 'en'],
    ['BJ', 'fr'], ['BM', 'en'], ['BT', 'dz'], ['BO', 'es'], ['BA', 'bs'],
    ['BW', 'en'], ['BR', 'pt'], ['BN', 'ms'], ['BG', 'bg'], ['BF', 'fr'],
    ['BI', 'rn'], ['KH', 'km'], ['CM', 'fr'], ['CA', 'en'], ['CV', 'pt'],
    ['KY', 'en'], ['CF', 'fr'], ['TD', 'fr'], ['CL', 'es'], ['CN', 'zh'],
    ['CO', 'es'], ['KM', 'ar'], ['CD', 'fr'], ['CG', 'fr'], ['CR', 'es'],
    ['HR', 'hr'], ['CU', 'es'], ['CY', 'el'], ['CZ', 'cs'], ['DK', 'da'],
    ['DJ', 'ar'], ['DM', 'en'], ['DO', 'es'], ['EC', 'es'], ['EG', 'ar'],
    ['SV', 'es'], ['GQ', 'es'], ['ER', 'ti'], ['EE', 'et'], ['SZ', 'en'],
    ['ET', 'am'], ['FI', 'fi'], ['FR', 'fr'], ['GA', 'fr'], ['GM', 'en'],
    ['GE', 'ka'], ['DE', 'de'], ['GH', 'en'], ['GR', 'el'], ['GD', 'en'],
    ['GT', 'es'], ['GN', 'fr'], ['GW', 'pt'], ['GY', 'en'], ['HT', 'ht'],
    ['HN', 'es'], ['HK', 'zh'], ['HU', 'hu'], ['IS', 'is'], ['IN', 'hi'],
    ['ID', 'id'], ['IR', 'fa'], ['IQ', 'ar'], ['IE', 'en'], ['IL', 'he'],
    ['IT', 'it'], ['CI', 'fr'], ['JM', 'en'], ['JP', 'ja'], ['JO', 'ar'],
    ['KZ', 'kk'], ['KE', 'en'], ['KI', 'en'], ['KP', 'ko'], ['KR', 'ko'],
    ['KW', 'ar'], ['KG', 'ky'], ['LA', 'lo'], ['LV', 'lv'], ['LB', 'ar'],
    ['LS', 'en'], ['LR', 'en'], ['LY', 'ar'], ['LI', 'de'], ['LT', 'lt'],
    ['LU', 'lb'], ['MO', 'zh'], ['MK', 'mk'], ['MG', 'mg'], ['MW', 'en'],
    ['MY', 'ms'], ['MV', 'dv'], ['ML', 'fr'], ['MT', 'mt'], ['MH', 'en'],
    ['MQ', 'fr'], ['MR', 'ar'], ['MU', 'en'], ['MX', 'es'], ['FM', 'en'],
    ['MD', 'ro'], ['MC', 'fr'], ['MN', 'mn'], ['ME', 'sr'], ['MA', 'ar'],
    ['MZ', 'pt'], ['MM', 'my'], ['NA', 'en'], ['NR', 'en'], ['NP', 'ne'],
    ['NL', 'nl'], ['NZ', 'en'], ['NI', 'es'], ['NE', 'fr'], ['NG', 'en'],
    ['NO', 'no'], ['NP', 'ne'], ['OM', 'ar'], ['PK', 'ur'], ['PA', 'es'],
    ['PG', 'en'], ['PY', 'es'], ['PE', 'es'], ['PH', 'tl'], ['PL', 'pl'],
    ['PT', 'pt'], ['QA', 'ar'], ['RO', 'ro'], ['RU', 'ru'], ['RW', 'rw'],
    ['KN', 'en'], ['LC', 'en'], ['VC', 'en'], ['WS', 'sm'], ['ST', 'pt'],
    ['SA', 'ar'], ['SN', 'fr'], ['RS', 'sr'], ['SC', 'en'], ['SL', 'en'],
    ['SG', 'en'], ['SK', 'sk'], ['SI', 'sl'], ['SB', 'en'], ['SO', 'so'],
    ['ZA', 'en'], ['SS', 'en'], ['ES', 'es'], ['LK', 'si'], ['SD', 'ar'],
    ['SR', 'nl'], ['SE', 'sv'], ['SY', 'ar'], ['CH', 'de'], ['TW', 'zh'],
    ['TJ', 'tg'], ['TZ', 'sw'], ['TH', 'th'], ['TL', 'tet'], ['TG', 'fr'],
    ['TO', 'en'], ['TT', 'en'], ['TN', 'ar'], ['TR', 'tr'], ['TM', 'tk'],
    ['UG', 'en'], ['UA', 'uk'], ['AE', 'ar'], ['GB', 'en'], ['US', 'en'],
    ['UY', 'es'], ['UZ', 'uz'], ['VU', 'bi'], ['VA', 'it'], ['VE', 'es'],
    ['VN', 'vi'], ['YE', 'ar'], ['ZM', 'en'], ['ZW', 'en']
  ]);
  
  return countryLanguageMap.get(countryCode) ?? 'en';
};